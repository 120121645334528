import React, { useRef, useEffect } from 'react'
// import JsBarcode from 'jsbarcode'
// node_modules/jsbarcode/bin/JsBarcode // main: bin/JsBarcode.js
import JsBarcode from 'jsbarcode'
// node_modules/jsbarcode/bin/barcodes/CODE128
// @ts-ignore
// import JsBarcode from 'jsbarcode/bin/barcodes/CODE128/CODE128'

// TODO: reduce jsbarcode size - jsbarcode: 57.16 KB

interface BarcodeProps {
  value: string
}

export function Barcode({ value }: BarcodeProps) {
  const style = {
    maxWidth: '100%',
  }
  const canvasRef = useRef<HTMLCanvasElement>(null)

  /**
   * @description クワイエットゾーン。バーコードの左右の余白のこと。左右それぞれに最小エレメントの10倍以上が必要です。
   * @see https://www.keyence.co.jp/ss/products/autoid/codereader/basic_code128.jsp
   * @see https://www.keyence.co.jp/ss/products/autoid/codereader/basic_mechanism.jsp
   */
  const QUIET_ZONE = 10
  
  /**
   * @description クワイエットゾーンに加えるバッファ。読み取りの安定性を高めるため、規格で定められた値にバッファを加えた余白を確保してください。
   * @see https://www.keyence.co.jp/ss/products/autoid/codereader/basic_code128.jsp
   * @see https://www.keyence.co.jp/ss/products/autoid/codereader/basic_mechanism.jsp
   */
  const QUIET_ZONE_BUFFER = 20

  useEffect(() => {
    const quietZone = QUIET_ZONE + QUIET_ZONE_BUFFER
    JsBarcode(canvasRef.current, value, {
      format: 'CODE128',
      displayValue: false,
      height: 96,
      margin: 0,
      marginLeft: quietZone,
      marginRight: quietZone,
    })
  }, [canvasRef, value])

  return <canvas ref={canvasRef} style={style}/>
}
